import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';

import './index.less';
import {
  Button,
  Form,
  Input,
  Upload,
  UploadFile,
  UploadProps,
  message
} from 'antd';
import { login, workTicket } from '@/api/login';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { useNavigate } from 'react-router-dom';

interface IProps {
  children?: ReactNode;
}
const Support: FC<IProps> = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [form] = Form.useForm();
  const email = Form.useWatch('email', form);
  const content = Form.useWatch('content', form);

  const onFinishFailed = ({ errorFields }: any) => {
    message.error(errorFields[0].errors[0], 1.5);
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('xImage', file as RcFile);
      });
      formData.append('content', content);
      formData.append('email', email);
      const { data, success, msg }: any = await workTicket(formData);
      if (success) {
        message.success('submit success');
        setTimeout(() => {
          navigate('/home');
        }, 1600);
      } else {
        message.error(msg);
      }
    } catch (error) {
      const msg = (error as any)?.response?.data?.msg;
      msg && message.error(msg);
    }
    setLoading(false);
  };

  const props: UploadProps = {
    listType: 'picture',
    maxCount: 4,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isPNG = [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/webp'
      ].includes(file.type);

      const isLt6M = file.size / 1024 / 1024 < 6;

      if (fileList.length >= 4) {
        message.error('Up to 4 images can be uploaded');
        return false;
      } else if (!isPNG) {
        message.error(`${file.name} is not a png file`);
        return false;
      } else if (!isLt6M) {
        message.error('Image must smaller than 6MB!');
        return false;
      }
      setFileList([...fileList, file]);
      return false;
    },
    fileList
  };
  return (
    <div className="support-container">
      <div className="container">
        <div className="support-title">Submit a request</div>
        <Form
          form={form}
          name="registerForm"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Your email address"
            name="email"
            validateStatus="success"
            rules={[
              { required: true, message: 'Email can not be empty' },
              () => ({
                validator(_, value) {
                  const reg =
                    /^[A-Za-z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                  if (!value || reg.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Email is invalid'));
                }
              })
            ]}
          >
            <Input placeholder="Email address" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="content"
            validateStatus="success"
            rules={[
              {
                required: true,
                message: 'Content can not be empty'
              }
            ]}
          >
            <Input.TextArea placeholder="Content" autoSize={{ minRows: 8 }} />
          </Form.Item>

          <Form.Item
            label="Image Attachments(optional)"
            valuePropName="fileList"
          >
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Upload (Max: 4)</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button
              className="submitBtn"
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        <div className="contact-us">
          <span className="label">Contact us:</span>
          <a href="mailto:go.xback@gmail.com">
            <span>go.xback@gmail.com</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Support;
