import React, { Suspense, useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import routes from './router';
import AppHeader from '@/components/app-header';
import AppFooter from '@/components/app-footer';
import { useSetRootFontSize } from './hooks';
import { clearToken, getToken, setToken } from './utils/cookie';
import {
  getBaseToken,
  getIpInfo,
  getOrderList,
  getUserInfo
} from './api/login';
import { message } from 'antd';
import { useAppDispatch } from './store';
import {
  IAccountState,
  updateAccount,
  updateActivityCode,
  updateCountryCode,
  updateOrder
} from './store/module/account';
import queryString from 'query-string';
import Cookies from 'js-cookie';

interface IBaseToken {
  deviceCode: string;
  expiresAt: number;
  token: string;
}

function App() {
  const dispatch = useAppDispatch();
  const { token, type, activityCode } = queryString.parse(useLocation().search);
  const { pathname } = useLocation();
  const isAuth = pathname.startsWith('/auth');
  useSetRootFontSize();

  const baseToken = getToken();
  const fetchUserInfo = async () => {
    try {
      const orderListInfo = await getOrderList();
      if (orderListInfo.success) {
        dispatch(updateOrder(orderListInfo.data as any[]));
      } else {
        message.error(orderListInfo.msg);
      }

      const { data, success, msg } = await getUserInfo<
        IAccountState['accountInfo']
      >();
      if (success) {
        dispatch(updateAccount(data));
      } else {
        message.error(msg);
        clearToken();
        setTimeout(() => {
          location.replace('/auth/signin');
        }, 600);
      }
    } catch (error) {
      const msg = (error as any)?.response?.data?.msg;
      msg && message.error(msg);
    }
  };
  const fetchIpInfo = async () => {
    const data: any = await getIpInfo();
    dispatch(updateCountryCode(data?.country_code));
  };

  if (activityCode) {
    Cookies.set('activityCode', activityCode);
  }
  const activityCodeCookie = activityCode || Cookies.get('activityCode');
  !!activityCodeCookie && dispatch(updateActivityCode(activityCodeCookie));

  const init = async () => {
    await fetchIpInfo();
    if (token && type) {
      const { data, success, msg } = await getBaseToken<IBaseToken>(
        token as string,
        type as string
      );
      if (success) {
        setToken(data.token);
        await fetchUserInfo();
      } else {
        message.error(msg);
      }
    } else if (baseToken) {
      await fetchUserInfo();
    }
  };

  useEffect(() => {
    init();
  }, [token]);

  return (
    <div className="App">
      {isAuth ? null : <AppHeader />}
      <Suspense fallback={<div style={{ height: '100vh' }}></div>}>
        <div className="main">{useRoutes(routes)}</div>
      </Suspense>
      {isAuth ? null : <AppFooter />}
    </div>
  );
}

export default App;
