import React, { memo, useEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import LogoImg from '@/assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import ToggleIcon from '../toggleIcon';
import './index.less';
import { useAppDispatch, useAppSelector } from '@/store';
import classnames from '@/utils/classnames';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Checkbox, Dropdown, MenuProps, Modal, message } from 'antd';
import { updateAccount } from '@/store/module/account';
import { clearToken } from '@/utils/cookie';
import AvatarImg from '@/assets/images/avatar.svg';
import dayjs from '@/utils/day';
import { logout } from '@/api/login';
const DelAccountContent = ({ submitChange }: { submitChange: () => void }) => {
  const [flag, setFlag] = useState(false);
  const deleteAccount = async () => {
    if (!flag) {
      message.warn(
        'Please read and confirm the instructions for deleting an account'
      );
      return;
    }
    const { success, msg } = await logout();
    if (success) {
      submitChange();
      message.success('Delete account successfully');
    } else {
      message.error(msg);
    }
  };

  return (
    <>
      <div>
        You can delete your account at any time. If you change your mind, you
        may not be able to recover it.
      </div>
      <div>Deleting your account means:</div>
      <div>-You’ll lose all the data and content in this account.</div>
      <div>
        -You won&apos;t be able to use
        <span className="notranslate">xBack</span> services where you sign in
        with this account.
      </div>
      <div>
        -You’ll lose access to subscriptions you bought with this account.
      </div>
      <div className="checkBox">
        <Checkbox
          checked={flag}
          onChange={(e) => {
            setFlag(e.target.checked);
          }}
        >
          I have read and understood
        </Checkbox>
        <a
          href="https://www.xback.io/xback-app-account-cancellation-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          the instructions for deleting an account
        </a>
      </div>
      <div className="delBtn" onClick={deleteAccount}>
        Delete account
      </div>
    </>
  );
};

interface IProps {
  children?: ReactNode;
}

const AppHeader: FC<IProps> = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const { accountInfo, orderList } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();

  const [collapsed, setCollapsed] = useState(true);

  const setBodyOverflow = (collapsed: boolean) => {
    const bodyElem = document.querySelector('body');
    bodyElem?.setAttribute(
      'style',
      `overflow: ${collapsed ? 'auto' : 'hidden'}`
    );
  };
  useEffect(() => {
    setBodyOverflow(collapsed);
  }, [collapsed]);

  const signout = () => {
    navigate(pathname);
    clearToken();
    dispatch(updateAccount(null));
  };
  const showSignout = () => {
    Modal.info({
      closable: true,
      title: 'Note',
      content: 'Are you sure to log out?',
      okText: 'Continue',
      maskClosable: true,
      className: 'custom-modal',
      icon: <></>,
      onOk: async () => {
        setCollapsed(true);
        signout();
      }
    });
  };

  const showLogout = () => {
    Modal.info({
      closable: true,
      title: 'Delete Account',
      content: (
        <DelAccountContent
          submitChange={() => {
            setCollapsed(true);
            signout();
            Modal.destroyAll();
          }}
        />
      ),
      width: 600,
      maskClosable: true,
      className: 'custom-modal delAccount-modal',
      icon: <></>
    });
  };

  const showAccount = async () => {
    const userInfoModal = Modal.info({
      closable: true,
      title: 'Account Information',
      content: (
        <>
          <div>{`Email: ${accountInfo?.email}`}</div>

          <div>
            <div>My VIP Plans:</div>
            {orderList && orderList?.length > 0 && (
              <div className="orderListWrap">
                {orderList.map((item, index) => {
                  return (
                    <div className="order-item" key={index}>
                      <div className="order-item-col">
                        <div className="order-item-col-left">
                          <span className="order-item-col-label">
                            Ordered at
                          </span>
                          <span className="order-item-col-val">
                            {dayjs(+item?.purchaseDateMs).format(
                              'HH:mm:ss on MMM D, YYYY'
                            )}
                          </span>
                        </div>
                        <div className="order-item-col-right">{`+${item.duration} days`}</div>
                      </div>
                      <div className="order-item-col">
                        <div className="order-item-col-left">
                          <span className="order-item-col-label">
                            Expire at
                          </span>
                          <span className="order-item-col-val">
                            {dayjs(+item?.expireDateMs).format(
                              'HH:mm:ss on MMM D, YYYY'
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="order-item-col">
                        <div className="order-item-col-left">
                          <span className="order-item-col-label">
                            Order source
                          </span>
                          <span className="order-item-col-val">
                            {' '}
                            {item?.payMethod}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {!orderList?.length && (
            <div className="upgrade-btn">
              <button
                onClick={() => {
                  navigate('/vip', {
                    state: {
                      upgrade: true
                    }
                  });
                  userInfoModal.destroy();
                }}
              >
                Upgrade to VIP now
              </button>
            </div>
          )}

          <div className="del-btn">
            <button
              onClick={() => {
                showLogout();
              }}
              className="delAccount-btn"
            >
              Delete account
            </button>
          </div>
        </>
      ),
      // okText: undefined,
      maskClosable: true,
      className: 'custom-modal userInfo-modal',
      icon: <></>
    });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          onClick={() => {
            showAccount();
          }}
        >
          Account
        </a>
      )
    },
    {
      key: '2',
      label: (
        <a
          onClick={() => {
            showSignout();
          }}
        >
          Sign out
        </a>
      )
    }
    // {
    //   key: '3',
    //   label: (
    //     <a
    //       onClick={() => {
    //         showLogout();
    //       }}
    //       className="delAccount-btn"
    //     >
    //       Delete account
    //     </a>
    //   )
    // }
  ];

  const downloadJSAtOnload = () => {
    const element = document.createElement('script');
    element.src = '/translate/translate.js';
    document.body.appendChild(element);
    setTimeout(() => {
      const translateElem = document.querySelector(
        '#google_translate_element '
      );
      translateElem?.setAttribute('style', 'display: block');
    }, 1200);
  };

  useEffect(() => {
    setTimeout(() => {
      downloadJSAtOnload();
    }, 0);
    window.scrollTo(0, 0);

    const hash = location.hash;
    if (hash) {
      const elem = document.querySelector(hash);
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    }
  }, []);

  return (
    <div className="header-container">
      <div className="container header-wrap">
        <div className="header-left">
          <img
            className="header-logo"
            src={LogoImg}
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
        <div className="header-right">
          <ToggleIcon
            className="header-toggler"
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <div className={classnames('header-nav', { collapsed })}>
            <ToggleIcon
              className="header-toggler"
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
            <div className="nav-link googleTranslate-link">
              <div id="google_translate_element"></div>
            </div>
            <a
              className="nav-link"
              onClick={() => {
                setCollapsed(true);
                navigate('/home#download', {
                  state: {
                    tag: 'download'
                  }
                });
              }}
            >
              Try now
            </a>
            <a
              className="nav-link"
              onClick={() => {
                setCollapsed(true);
                navigate('/vip');
              }}
            >
              <AnimationOnScroll
                animateIn="animate__wobble"
                offset={0}
                className="animate__repeat-2"
              >
                VIP
              </AnimationOnScroll>
            </a>
            {/* <a
              className="nav-link"
              onClick={() => {
                setCollapsed(true);
                location.href = 'https://blog.xback.io/';
              }}
            >
              Next generation VPN
            </a>

            <a
              className="nav-link"
              onClick={() => {
                setCollapsed(true);
                navigate('/support');
              }}
            >
              Support
            </a> */}

            {!!accountInfo && (
              <a
                className={classnames('nav-link', 'userInfo', {
                  isSignin: !!accountInfo?.email
                })}
                onClick={() => {
                  showAccount();
                }}
              >
                Account
              </a>
            )}

            <button
              className={classnames('signin-btn', 'nav-link', {
                isSignin: !!accountInfo?.email
              })}
              onClick={() => {
                if (accountInfo?.email) {
                  showSignout();
                } else {
                  setCollapsed(true);
                  setBodyOverflow(true);
                  navigate('/auth/signin');
                }
              }}
            >
              {accountInfo?.email ? 'Sign out' : 'Sign in'}
            </button>

            {/* {!!accountInfo && (
              <a
                className={classnames('nav-link', 'logout-btn', {
                  isSignin: !!accountInfo?.email
                })}
                onClick={() => {
                  showLogout();
                }}
              >
                Delete account
              </a>
            )} */}

            {accountInfo?.email && (
              <>
                <Dropdown menu={{ items }} placement="bottom">
                  <img className="avatar" src={AvatarImg} alt="" />
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AppHeader);
