import React from 'react';
import type { FC, ReactNode } from 'react';
import { ReactComponent as IconSwitch } from '@/assets/images/icons/switch.svg';
import classnames from '@/utils/classnames';

interface IProps {
  children?: ReactNode;
  className?: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}
const ToggleIcon: FC<IProps> = ({ className, collapsed, setCollapsed }) => {
  return (
    <div
      className={classnames(className, { collapsed })}
      onClick={() => {
        setCollapsed(!collapsed);
      }}
    >
      <IconSwitch />
    </div>
  );
};

export default ToggleIcon;
