import Support from '@/views/support';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

const Home = lazy(() => import('@/views/home'));
const VIP = lazy(() => import('@/views/vip'));

const Auth = lazy(() => import('@/views/auth'));
const SignUp = lazy(() => import('@/views/auth/c-views/signup'));
const SignIn = lazy(() => import('@/views/auth/c-views/signin'));
const Reset = lazy(() => import('@/views/auth/c-views/reset'));
const PaySuccess = lazy(() => import('@/views/auth/c-views/paymentSuccess'));
const PayCancel = lazy(() => import('@/views/auth/c-views/paymentCancel'));
const PayFail = lazy(() => import('@/views/auth/c-views/paymentFail'));
const DelAccount = lazy(() => import('@/views/auth/c-views/delAccount'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/home" />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/vip',
    element: <VIP />
  },
  {
    path: '/support',
    element: <Support />
  },
  {
    path: '/auth',
    element: <Auth />,
    children: [
      {
        path: '/auth',
        element: <Navigate to={'/auth/signin'} />
      },
      {
        path: '/auth/signin',
        element: <SignIn />
      },
      {
        path: '/auth/signup',
        element: <SignUp />
      },
      {
        path: '/auth/reset',
        element: <Reset />
      },
      {
        path: '/auth/paysuccess',
        element: <PaySuccess />
      },
      {
        path: '/auth/paycancel',
        element: <PayCancel />
      },
      {
        path: '/auth/payfail',
        element: <PayFail />
      },
      {
        path: '/auth/delAccount',
        element: <DelAccount />
      }
    ]
  }
];

export default routes;
